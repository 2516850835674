import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = { class: "card-header border-0 cursor-pointer" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = {
  key: 0,
  class: "fw-bolder m-0"
}
const _hoisted_7 = {
  key: 1,
  class: "fw-bolder m-0"
}
const _hoisted_8 = { class: "card-body border-top p-9" }
const _hoisted_9 = { class: "row mb-6" }
const _hoisted_10 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = { class: "input-group mb-5" }
const _hoisted_13 = {
  disabled: "",
  selected: "",
  value: "-1"
}
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "row mb-6" }
const _hoisted_16 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_17 = { class: "col-lg-8" }
const _hoisted_18 = { class: "form-check form-check-custom form-check-solid me-5" }
const _hoisted_19 = ["name", "value"]
const _hoisted_20 = { class: "form-check-label" }
const _hoisted_21 = { class: "row mb-6" }
const _hoisted_22 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_23 = { class: "col-lg-8" }
const _hoisted_24 = { class: "input-group mb-5" }
const _hoisted_25 = { class: "row mb-6" }
const _hoisted_26 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_27 = { class: "col-lg-8" }
const _hoisted_28 = { class: "input-group mb-5" }
const _hoisted_29 = { class: "input-group mb-5" }
const _hoisted_30 = { class: "row mb-10" }
const _hoisted_31 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_32 = { class: "col-lg-8 col-form-label" }
const _hoisted_33 = { class: "required form-label" }
const _hoisted_34 = { class: "form-label" }
const _hoisted_35 = { class: "row mb-6" }
const _hoisted_36 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_37 = { class: "col-lg-8" }
const _hoisted_38 = { class: "input-group mb-5" }
const _hoisted_39 = { class: "input-group mb-5" }
const _hoisted_40 = { class: "row mb-6" }
const _hoisted_41 = {
  key: 0,
  class: "col-lg-8"
}
const _hoisted_42 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_1"
}
const _hoisted_43 = { class: "ms-5 fw-bold fs-4" }
const _hoisted_44 = {
  key: 1,
  class: "col-lg-8 col-form-label"
}
const _hoisted_45 = { class: "fw-bold fs-5" }
const _hoisted_46 = {
  href: "#",
  class: "",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_1"
}
const _hoisted_47 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_48 = { class: "modal-lg modal-dialog modal-dialog-centered" }
const _hoisted_49 = { class: "modal-content" }
const _hoisted_50 = { class: "modal-header" }
const _hoisted_51 = { class: "modal-title" }
const _hoisted_52 = { class: "modal-body" }
const _hoisted_53 = { class: "row" }
const _hoisted_54 = { class: "col-lg-8" }
const _hoisted_55 = { class: "illustrations-container" }
const _hoisted_56 = ["src", "alt", "onClick"]
const _hoisted_57 = { class: "col-lg-4" }
const _hoisted_58 = { class: "modal-footer" }
const _hoisted_59 = {
  key: 0,
  disabled: "",
  type: "button",
  class: "btn btn-primary"
}
const _hoisted_60 = {
  key: 1,
  type: "button",
  class: "btn btn-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_61 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_62 = { class: "indicator-label" }
const _hoisted_63 = { class: "indicator-progress" }
const _hoisted_64 = { class: "indicator-validate" }
const _hoisted_65 = { class: "indicator-label" }
const _hoisted_66 = { class: "indicator-progress" }
const _hoisted_67 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (!this.$route.query.id)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t("pages.driveManagement.documents.addDoc")), 1))
              : (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_ctx.$t("pages.driveManagement.documents.updateDoc")), 1))
          ])
        ]),
        _createVNode(_component_Form, {
          id: "addDocForm",
          "validation-schema": _ctx.validateAddDoc
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docCat")), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("select", {
                      name: "category",
                      as: "select",
                      class: "form-select",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.document.category) = $event))
                    }, [
                      _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t("pages.driveManagement.documents.chooseOption")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.documentCategories, (category) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: category.id,
                          value: category.id
                        }, _toDisplayString(category.label), 9, _hoisted_14))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, this.document.category]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docTarget")), 1),
                _createElementVNode("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.userTypes, (userType) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "input-group mb-5",
                      key: userType.id
                    }, [
                      _createElementVNode("label", _hoisted_18, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          name: userType.label,
                          value: userType.id,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.document.user_types) = $event)),
                          class: "form-check-input"
                        }, null, 8, _hoisted_19), [
                          [_vModelCheckbox, this.document.user_types]
                        ]),
                        _createElementVNode("span", _hoisted_20, _toDisplayString(userType.label), 1)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docOrder")), 1),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_Field, {
                      name: "order",
                      type: "text",
                      class: "form-control",
                      placeholder: "Ordre",
                      modelValue: this.document.order,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.document.order) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("pages.driveManagement.documents.filename")), 1),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", {
                      class: "input-group-text",
                      id: "basic-addon1"
                    }, "🇫🇷", -1)),
                    _createVNode(_component_Field, {
                      name: "label",
                      type: "text",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.documents.frFileName'),
                      modelValue: this.document.label,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.document.label) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _cache[10] || (_cache[10] = _createElementVNode("span", {
                      class: "input-group-text",
                      id: "basic-addon1"
                    }, "🇬🇧", -1)),
                    _createVNode(_component_Field, {
                      name: "label_en",
                      type: "text",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.documents.enFileName'),
                      modelValue: this.document.label_en,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.document.label_en) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("pages.driveManagement.documents.file")), 1),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("pages.driveManagement.documents.frFile")), 1),
                  _createVNode(_component_Field, {
                    name: "frFile",
                    type: "file",
                    onChange: _ctx.addFilesFr,
                    class: "form-control mb-6",
                    accept: "image/*, .pdf, .pptx, .txt, .xlsx, .docx"
                  }, null, 8, ["onChange"]),
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("pages.driveManagement.documents.enFile")), 1),
                  _createVNode(_component_Field, {
                    name: "enFile",
                    type: "file",
                    onChange: _ctx.addFilesEn,
                    class: "form-control",
                    accept: "image/*, .pdf, .pptx, .txt, .xlsx, .docx"
                  }, null, 8, ["onChange"])
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("pages.driveManagement.documents.video")), 1),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    _createVNode(_component_Field, {
                      name: "video",
                      as: "textarea",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.documents.video_fr'),
                      modelValue: this.document.video,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.document.video) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createVNode(_component_Field, {
                      name: "video_en",
                      as: "textarea",
                      type: "textarea",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.documents.video_en'),
                      modelValue: this.document.video_en,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.document.video_en) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_40, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-bold fs-6" }, "Illustration", -1)),
                (_ctx.finalIllustration === '')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                      _createElementVNode("button", _hoisted_42, _toDisplayString(_ctx.$t("pages.driveManagement.documents.pickIllustration")), 1),
                      _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.finalIllustration), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      _createElementVNode("span", _hoisted_45, [
                        _createTextVNode(_toDisplayString(_ctx.$t("pages.driveManagement.documents.selectedIllustration")) + " ", 1),
                        _createElementVNode("a", _hoisted_46, _toDisplayString(_ctx.finalIllustration), 1),
                        _cache[11] || (_cache[11] = _createTextVNode(". "))
                      ])
                    ]))
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, [
                      _createElementVNode("h5", _hoisted_51, _toDisplayString(_ctx.$t("pages.driveManagement.documents.documentIllustration")), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("div", {
                        class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
                        "data-bs-dismiss": "modal",
                        "aria-label": "Close"
                      }, [
                        _createElementVNode("span", { class: "svg-icon svg-icon-2x" }, [
                          _createElementVNode("i", { class: "fas fa-times" })
                        ])
                      ], -1))
                    ]),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.illustrations, (illustration) => {
                              return (_openBlock(), _createElementBlock("img", {
                                key: illustration.label,
                                src: illustration.src,
                                alt: illustration.label,
                                class: _normalizeClass(illustration.class),
                                onClick: ($event: any) => (_ctx.selectImg(illustration))
                              }, null, 10, _hoisted_56))
                            }), 128))
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.driveManagement.documents.pleasePick")), 1),
                          _createElementVNode("p", null, [
                            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "fw-bold" }, "Illustration :", -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.selectedIllustration), 1)
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_58, [
                      (_ctx.finalIllustration === '')
                        ? (_openBlock(), _createElementBlock("button", _hoisted_59, _toDisplayString(_ctx.$t("pages.driveManagement.documents.pick")), 1))
                        : (_openBlock(), _createElementBlock("button", _hoisted_60, _toDisplayString(_ctx.$t("pages.driveManagement.documents.pick")), 1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_61, [
              (this.$route.query.id)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "submit",
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (this.onSubmit && this.onSubmit(...args))),
                    id: "kt_account_profile_details_submit",
                    ref: "submitButton",
                    class: "btn btn-primary"
                  }, [
                    _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.$t("general.update")), 1),
                    _createElementVNode("span", _hoisted_63, [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                      _cache[15] || (_cache[15] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                    ]),
                    _createElementVNode("span", _hoisted_64, [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                      _cache[16] || (_cache[16] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                    ])
                  ], 512))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "submit",
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (this.onSubmit && this.onSubmit(...args))),
                    id: "kt_account_profile_details_submit",
                    ref: "submitButton",
                    class: "btn btn-primary"
                  }, [
                    _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.$t("general.add")), 1),
                    _createElementVNode("span", _hoisted_66, [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                    ]),
                    _createElementVNode("span", _hoisted_67, [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                    ])
                  ], 512))
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ])
  ]))
}